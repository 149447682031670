<div class="carousel-wrapper">
  <div #Carousel class="carousel">
    <ngx-slick-carousel
      #slideElement
      class="carousel"
      #slickModal="slick-carousel"
      [config]="slideConfig"
      (beforeChange)="onBeforeChange($event)"
      (afterChange)="onAfterChange($event)"
      (init)="onInit()">
      <ng-container *ngFor="let image of imageSelection; trackBy: trackByFn; let i = index">
        <div ngxSlickItem class="carousel__slide" [ngClass]="{ 'slick-target': nextIndex === i }">
          <img [src]="image" (load)="onLoad(image)" (click)="lightbox.open(image)" />
        </div>
      </ng-container>
    </ngx-slick-carousel>
  </div>
  <div class="carousel__left-arrow-wrapper" (click)="slickModal.slickPrev()">
    <div [class]="(theme.name | async) + '-carousel-arrow-prev'"></div>
  </div>
  <div class="carousel__right-arrow-wrapper" (click)="slickModal.slickNext()">
    <div [class]="(theme.name | async) + '-carousel-arrow-next'"></div>
  </div>
  <div [class]="(theme.name | async) + '-carousel__nav carousel__nav'">
    <ng-container *ngFor="let dot of imageSelection; let i = index">
      <div
        [class]="'carousel__nav__dot ' + (theme.name | async) + '-carousel-dot'"
        [ngClass]="{ 'is-current': i === currentSlide }"
        (click)="slickModal.slickGoTo(i)">
        <svg *ngIf="i === currentSlide" class="progress-svg" width="26" height="26">
          <g transform="translate(13,13)">
            <circle class="circle-bg" r="12" cx="0" cy="0"></circle>
            <circle class="circle-go" r="12" cx="0" cy="0"></circle>
          </g>
        </svg>
      </div>
    </ng-container>
  </div>
</div>
