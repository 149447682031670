<div class="brochure" id="brochure-container">
  <div class="user_notification">
    <h2 *ngIf="isBrowserMozilla">
      Dear customer, this page is optimized for Safari and Chrome, the movies can't be played with
      Firefox. Please switch to Safari or Chrome.
    </h2>
  </div>
  <ng-container *ngFor="let section of structure; let i = index">
    <!-- Optional: Driving Experience -->
    <ng-container *ngIf="section.type === 'drivingexperience'">
      <app-hero
        class="drivingEXP"
        [isBrowserMozilla]="isBrowserMozilla"
        [src]="resources?.videos[section.index]"
        [isDrivingExp]="true"
        [modelName]="api.modelName | async"
        modelDescription="Emotional Drive."
        videoId="Emotional Drive"></app-hero>
    </ng-container>

    <!-- Optional: Driving Experience Separator -->
    <ng-container *ngIf="section.type === 'drivingexperience' && drivinexperienceExists">
      <app-section-separator [separatorText]="'Exterior'"></app-section-separator>
    </ng-container>

    <!-- Hero Section without Driving Experience-->
    <ng-container *ngIf="section.type === 'hero' && !drivinexperienceExists">
      <app-hero
        [src]="resources?.videos[section.index]"
        [modelName]="api.modelName | async"
        modelDescription=""></app-hero>
    </ng-container>

    <!-- Separator -->
    <ng-container *ngIf="section.type === 'hero' && !drivinexperienceExists">
      <app-section-separator
        [separatorText]="'Exterior'"
        style="margin-bottom: 4em"></app-section-separator>
    </ng-container>

    <!-- Hero with Driving Experience -->
    <ng-container *ngIf="section.type === 'hero' && drivinexperienceExists">
      <app-video
        [id]="'exterior'"
        [videoId]="'exterior'"
        [scrollMagicId]="'scrollMagicVideoExterior'"
        [src]="resources?.videos[section.index]"
        [fileNameExtension]="'Exterior'"></app-video>
    </ng-container>

    <!-- Optional: Driving Experience Separator -->
    <ng-container *ngIf="section.type === 'hero' && drivinexperienceExists">
      <app-section-separator></app-section-separator>
    </ng-container>

    <!-- Image Section -->
    <app-grid *ngIf="section.type === 'image'">
      <app-image *ngFor="let index of section.indeces" [src]="resources?.images[index]"></app-image>
    </app-grid>

    <!-- Separator -->
    <ng-container *ngIf="section.type === 'video'">
      <app-section-separator [separatorText]="'Interior'"></app-section-separator>
    </ng-container>

    <!-- Video Section with Driving Experience -->
    <ng-container *ngIf="section.type === 'video' && drivinexperienceExists">
      <app-video
        [id]="'interior'"
        [videoId]="'interior'"
        *ngFor="let index of section.indeces"
        [src]="resources?.videos[index]"
        [fileNameExtension]="'Interior'"
        [scrollMagicId]="'scrollMagicVideoInterior'"></app-video>
    </ng-container>

    <!-- Video Section without Driving Experience -->
    <ng-container *ngIf="section.type === 'video' && !drivinexperienceExists">
      <app-video
        [id]="'interior'"
        [videoId]="'interior'"
        *ngFor="let index of section.indeces"
        [src]="resources?.videos[index]"
        [fileNameExtension]="'Interior'"
        [scrollMagicId]="'scrollMagicVideoInterior'"></app-video>
    </ng-container>

    <!-- Separator -->
    <ng-container *ngIf="section.type === 'video'">
      <app-section-separator></app-section-separator>
    </ng-container>

    <!-- Carousel Section -->
    <app-carousel
      *ngIf="resources?.images.length && section.type === 'carousel'"
      [images]="resources?.images"
      [sectionIndices]="section.indeces"></app-carousel>
  </ng-container>

  <footer class="footer" [ngClass]="(theme.name | async) + '-footer'">
    <div *ngIf="!!emissionDataString.length" class="emission">
      <div class="emission-data__paragraph" (click)="openEmissionsModal()">
        <strong [innerHTML]="emissionDataString"></strong>
        <button [ngClass]="(theme.name | async) + '-info-button'"></button>
      </div>
      <div class="dat-hint__paragraph">
        <strong>DAT-Hinweis allgemein</strong>
        <p>
          Weitere Informationen zum offiziellen Kraftstoffverbrauch, den offiziellen spezifischen
          CO2-Emissionen und dem Stromverbrauch neuer Personenkraftwagen können dem 'Leitfaden über
          den Kraftstoffverbrauch, die CO2-Emissionen und den Stromverbrauch neuer
          Personenkraftwagen' entnommen werden, der an allen Verkaufsstellen, bei der Deutschen
          Automobil Treuhand GmbH (DAT), Hellmuth-Hirth-Str. 1, 73760 Ostfildern-Scharnhausen, und
          unter
          <a
            style="color: #1c69d4; word-wrap: break-word"
            href="http://www.dat.de/angebote/verlagsprodukte/leitfaden-kraftstoffverbrauch.html"
            target="_blank"
            rel="noopener"
            >http://www.dat.de/angebote/verlagsprodukte/leitfaden-kraftstoffverbrauch.html</a
          >
          unentgeltlich erhältlich ist. Die Angaben beziehen sich nicht auf ein einzelnes Fahrzeug
          und sind nicht Bestandteil des Angebots, sondern dienen allein Vergleichszwecken zwischen
          den verschiedenen Fahrzeugtypen. CO2-Emissionen, die durch die Produktion und
          Bereitstellung des Kraftstoffes bzw. anderer Energieträger entstehen, werden bei
          Ermittlung der CO2-Emissionen gemäß der Richtlinie 1999/94/EG nicht berücksichtigt.
          Hinweis nach Richtlinie 1999/94/EG: Der Kraftstoffverbrauch und die CO2-Emissionen eines
          Fahrzeugs hängen nicht nur von der effizienten Ausnutzung des Kraftstoffs durch das
          Fahrzeug ab, sondern werden auch vom Fahrverhalten und anderen nichttechnischen Faktoren
          beeinflusst. CO2 ist das für die Erderwärmung hauptsächlich verantwortliche Treibhausgas.
        </p>
      </div>
    </div>
    <div class="disclaimer">
      <div class="disclaimer__paragraph">
        <strong [innerHTML]="(theme.data | async)?.disclaimer?.title"></strong>
        <p [innerHTML]="(theme.data | async)?.disclaimer?.text"></p>
      </div>
    </div>
    <div class="disclaimer-links">
      <a [href]="(theme.data | async)?.imprint_link" target="_blank" rel="noopener">{{
        (theme.data | async)?.imprint_linktext
      }}</a>
      <div class="copyright">{{ (theme.data | async)?.copyright }}</div>
    </div>
  </footer>

  <div *ngIf="isLoading | async" id="loading-overlay">Loading ...</div>
</div>

<app-lightbox></app-lightbox>

<app-error *ngIf="errorMessage | async" [message]="errorMessage | async"></app-error>
