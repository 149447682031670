import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { CognitoAuthentication, LoginRedirectRequest } from '../utils/auth';

export interface UserInfo {
  userId: string;
  authData: string;
  basicAuth: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService extends CognitoAuthentication {
  private userInfo: UserInfo | null = null;
  constructor(private swUpdate: SwUpdate) {
    super();
  }
  getUseAuthData(): string {
    const currentUserInfo = this.getCurrentAuthUserInfo();
    if (currentUserInfo?.basicAuth) {
      return currentUserInfo?.authData ?? '';
    }
    return this.getToken();
  }

  getCurrentAuthUserInfo(): UserInfo | null {
    if (this.userInfo === null) {
      const storedUserInfo = localStorage.getItem('userInfo');
      if (storedUserInfo) {
        this.userInfo = JSON.parse(storedUserInfo);
      }
    }
    return this.userInfo;
  }
  // Perform login
  loginBasicAuth(username: string, password: string): string {
    const authData = 'Basic ' + window.btoa(username + ':' + password);
    this.storeUserInfo(username, authData, true);
    return authData;
  }

  public async loginMFA(email: string, password: string): Promise<void> {
    this.storeUserInfo(email, '', false);
    await super.login(email, password);
    return super.checkUserMFA();
  }

  public clearAuthData(): void {
    this.userInfo = null;
    super.clearLocalStore();
  }

  public storeUserInfo(userId: string, authData: string, basicAuth: boolean) {
    this.userInfo = { userId: userId, authData: authData, basicAuth: basicAuth };
    localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
  }

  public syncAuthData() {
    const authData = this.getUseAuthData();
    this.sendMessageToServiceWorker(authData);
  }

  public sendMessageToServiceWorker(message: string): void {
    if (this.swUpdate.isEnabled) {
      navigator.serviceWorker.ready.then(registration => {
        registration.active?.postMessage(message);
      });
    }
  }
}
