import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent {
  @Input() src: string;
  @Input() alt: string;
  @Input() modelName: string;
  @Input() modelDescription: string;
  @Input() isDrivingExp: boolean = false;
  @Input() videoId: string = '';
  @Input() isBrowserMozilla!: boolean;

  public videoPlaying: boolean;
  public selectedLanguage: Observable<string> = this.theme.state.pipe(
    filter(p => !!p?.language),
    map(p => p.language)
  );

  constructor(public theme: ThemeService) {}

  public toggleVideoState(isPlaying): void {
    this.videoPlaying = isPlaying;
  }

  public changeLanguage(event): void {
    this.theme.setLanguage(event.value);
  }
}
