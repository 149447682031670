export const environment = {
  production: false,
  enableAuthentication: true,
  apiUrl: 'https://api.evecp-tst.bmw.cloud',
  getAssetsEndpoint: '/v4/brochure/',
  cognitoConfig: {
    userPoolId: 'eu-west-1_f8MxWHjT7',
    clientId: '4kvncajt23dtgod9gpkf4lu49u',
  },
};
