import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LoginRedirectRequest } from 'src/app/utils/auth';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-screen',
  templateUrl: './login-screen.component.html',
  styleUrls: ['./login-screen.component.scss'],
})
export class LoginScreenComponent implements OnInit {
  private currentUserId: string = '';
  public loading = false;
  public applyCognito: string = '';

  public verifyMFACode: boolean = false;
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    const email = 'dl-evecp@list.bmw.com';
    const subject = encodeURIComponent('Apply Cognito account to access ' + window.location.origin);
    const emailBody = encodeURIComponent(
      'Hello, \nI want to apply a new cognito account to access ' + window.location.origin
    );
    this.applyCognito = `mailto:${email}?subject=${subject}&body=${emailBody}`;
  }
  onSubmitLegacy(form: NgForm): void {
    this.authService.loginBasicAuth(form.value.username, form.value.password);
    this.authService.syncAuthData();
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    const hostname = window.location.origin;
    window.location.href = hostname + returnUrl;
    //window.location.reload();
    //this.router.navigate([returnUrl]);
  }

  public async onSubmitMFA(form: NgForm): Promise<void> {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.loading = true;
    let queryParams = {};
    if (returnUrl != '/') {
      queryParams = { queryParams: { returnUrl: returnUrl } };
    }
    try {
      this.currentUserId = form.value.email;
      await this.authService.loginMFA(this.currentUserId, form.value.password);
      this.authService.syncAuthData();
      const hostname = window.location.origin;
      window.location.href = hostname + returnUrl;
    } catch (error: unknown) {
      switch (error) {
        case LoginRedirectRequest.RequestPasswordRest:
          this.router.navigate(['login', 'reset'], queryParams);
          break;
        case LoginRedirectRequest.RequestMFASetup:
          this.router.navigate(['login', 'mfa'], queryParams);
          break;
        case LoginRedirectRequest.TotpRequired:
          this.verifyMFACode = true;
          this.router.navigate(['login', 'mfa'], queryParams);
          break;
        case LoginRedirectRequest.VerifyEmail:
          this.router.navigate(['login', 'verify'], queryParams);
          break;
        default:
          if (error instanceof Error && error.message) {
            console.log(error);
            this.loading = false;
            this.snackBar.open(error.message, 'Close', {
              duration: 3000, // Duration in milliseconds
              panelClass: ['mat-toolbar', 'mat-warn'],
              verticalPosition: 'top',
            });
          }
      }
    }
  }
}
