import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { EmissionsModalComponent } from 'src/app/components/emissions-modal/emissions-modal.component';
import { IAssetUrls, IEmission } from 'src/app/interfaces/api';
import { IUISection } from 'src/app/interfaces/ui';
import { ApiService } from 'src/app/services/api.service';
import { StructureService } from 'src/app/services/structure.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TranslationService } from 'src/app/services/translation.service';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrochureComponent implements OnInit {
  public resources: IAssetUrls;
  public extCarouselImages: string[] = [];
  public intCarouselImages: string[] = [];

  public structure: Array<IUISection>;

  public errorMessage: Observable<string>;
  public emissionDataString: string = '';
  public emission: IEmission;
  public drivinexperienceExists = false;
  public assetCount = 0;
  public isLoading: Observable<boolean>;
  public isBrowserMozilla: boolean = false;

  private isElectricVehicle: boolean;
  private isHybridVehicle: boolean;

  constructor(
    public api: ApiService,
    public theme: ThemeService,
    private route: ActivatedRoute,
    private translation: TranslationService,
    public dialog: MatDialog,
    private viewportScroller: ViewportScroller,
    private structureService: StructureService,
    private platform: Platform
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading = this.api.loading$.pipe(delay(200)); // Delay for UI rednering if assets are cached, to void video moving
    if (this.platform.isBrowser) this.isBrowserMozilla = this.platform.FIREFOX;

    this.structureService.activeStructure$.subscribe(structure => {
      if (!structure) return;
      this.structure = structure.uiSection;
      // Set if driving experience is included
      this.drivinexperienceExists = this.structureService.structureContainsDrivingExperience(
        structure.uiSection
      );
      if (this.isBrowserMozilla) {
        if (this.drivinexperienceExists) {
          this.structure = this.structure.filter(
            section => section.type !== 'hero' && section.type !== 'video'
          );
        } else {
          this.structure = this.structure.filter(section => section.type !== 'video');
        }
      }
      // Calculate asset count
      let assetCount = 0;
      this.structure.forEach((section: IUISection) => {
        if (section.index !== undefined) {
          assetCount++;
        } else if (section.indeces && section.indeces.length) {
          assetCount += section.indeces.length;
        }
      });

      // Set asset count
      this.api.increaseAssetCount(assetCount);
    });

    this.api.loading$.subscribe(isLoading => {
      if (!isLoading) {
        const urlHash = this.getLocationHash();
        if (urlHash) {
          this.viewportScroller.scrollToAnchor(urlHash);
        }
      }
    });

    this.route.params
      .pipe(switchMap(params => this.api.getAssetUrls(params.configId)))
      .pipe(
        catchError(err => {
          if (err.status === 400) {
            this.errorMessage = this.translation.getTranslation('ERROR_NO_CODE');
          } else {
            this.errorMessage = this.translation.getTranslation('ERROR_WRONG_CODE');
          }
          this.api.loading$.next(false);
          return of(null);
        })
      )
      .subscribe((resources: IAssetUrls) => {
        if (!resources) return;
        this.resources = resources;
      });

    this.api.loadingTimeout$.subscribe(() => {
      this.errorMessage = this.translation.getTranslation('ERROR_TIMEOUT');
    });

    this.api.loadingError$.subscribe((error: boolean) => {
      if (error) {
        this.errorMessage = this.translation.getTranslation('ERROR_CODE_STILL_RENDERING');
      }
    });

    this.theme.getEmission().subscribe(emission => {
      this.emission = emission;

      if (!!this.emission) {
        // make sure that objects are assigned
        this.emission.nedcCorrelated = this.emission.nedcCorrelated || {};
        this.emission.wltp = this.emission.wltp || {};

        this.isElectricVehicle =
          !!this.emission.nedcCorrelated.electricConsumptionCombined ||
          !!this.emission.nedcCorrelated.pureElectricRangeCombined ||
          !!this.emission.wltp.pureElectricRangeCombined ||
          !!this.emission.wltp.electricConsumptionCombined;
        this.isHybridVehicle =
          this.isElectricVehicle &&
          (!!this.emission.nedcCorrelated.fuelConsumptionCombined ||
            !!this.emission.nedcCorrelated.co2Combined ||
            !!this.emission.wltp.co2Combined ||
            !!this.emission.wltp.fuelConsumptionCombined);

        for (const key in this.emission.nedcCorrelated) {
          this.emission.nedcCorrelated[key] = !!this.emission.nedcCorrelated[key]
            ? this.emission.nedcCorrelated[key]
            : '---';
        }

        for (const key in this.emission.wltp) {
          this.emission.wltp[key] = !!this.emission.wltp[key] ? this.emission.wltp[key] : '---';
        }

        if (this.isElectricVehicle) {
          // fill up missing values
          this.emission.nedcCorrelated.electricConsumptionCombined =
            this.emission.nedcCorrelated.electricConsumptionCombined || '---';
          this.emission.nedcCorrelated.pureElectricRangeCombined =
            this.emission.nedcCorrelated.pureElectricRangeCombined || '---';
          this.emission.wltp.electricConsumptionCombined =
            this.emission.wltp.electricConsumptionCombined || '---';
          this.emission.wltp.pureElectricRangeCombined =
            this.emission.wltp.pureElectricRangeCombined || '---';

          this.emissionDataString = `Stromverbrauch in kWh/100 km: ${this.emission.nedcCorrelated.electricConsumptionCombined} (NEFZ) / ${this.emission.wltp.electricConsumptionCombined} (WLTP) | Elektrische Reichweite in km: ${this.emission.nedcCorrelated.pureElectricRangeCombined} (NEFZ) / ${this.emission.wltp.pureElectricRangeCombined} (WLTP)`;

          if (this.isHybridVehicle) {
            // fill up missing values
            this.emission.nedcCorrelated.co2Combined =
              this.emission.nedcCorrelated.co2Combined || '---';
            this.emission.nedcCorrelated.fuelConsumptionCombined =
              this.emission.nedcCorrelated.fuelConsumptionCombined || '---';
            this.emission.wltp.co2Combined = this.emission.wltp.co2Combined || '---';
            this.emission.wltp.fuelConsumptionCombined =
              this.emission.wltp.fuelConsumptionCombined || '---';

            this.emissionDataString = `Verbrauch kombiniert in l / 100km: ${this.emission.nedcCorrelated.fuelConsumptionCombined} (NEFZ) / ${this.emission.wltp.fuelConsumptionCombined} (WLTP) | CO2 kombiniert in g / km: ${this.emission.nedcCorrelated.co2Combined} (NEFZ) / ${this.emission.wltp.co2Combined} (WLTP)<br/>Stromverbrauch in kWh/100 km: ${this.emission.nedcCorrelated.electricConsumptionCombined} (NEFZ) / ${this.emission.wltp.electricConsumptionCombined} (WLTP) | Elektrische Reichweite in km: ${this.emission.nedcCorrelated.pureElectricRangeCombined} (NEFZ) / ${this.emission.wltp.pureElectricRangeCombined} (WLTP)`;
          }
        } else {
          // fill up missing values
          this.emission.nedcCorrelated.co2Combined =
            this.emission.nedcCorrelated.co2Combined || '---';
          this.emission.nedcCorrelated.fuelConsumptionCombined =
            this.emission.nedcCorrelated.fuelConsumptionCombined || '---';
          this.emission.wltp.co2Combined = this.emission.wltp.co2Combined || '---';
          this.emission.wltp.fuelConsumptionCombined =
            this.emission.wltp.fuelConsumptionCombined || '---';

          this.emissionDataString = `Verbrauch kombiniert in l / 100km: ${this.emission.nedcCorrelated.fuelConsumptionCombined} (NEFZ) / ${this.emission.wltp.fuelConsumptionCombined} (WLTP) | CO2 kombiniert in g / km: ${this.emission.nedcCorrelated.co2Combined} (NEFZ) / ${this.emission.wltp.co2Combined} (WLTP)`;
        }
      }
    });
  }

  public getLocationHash(): string {
    return window.location.hash.substring(1);
  }

  public openEmissionsModal() {
    const dialogRef = this.dialog.open(EmissionsModalComponent, {
      panelClass: 'dialog-panel',
      maxWidth: '100vw',
      data: {
        isElectricVehicle: this.isElectricVehicle,
        emissionData: this.emission,
        isHybridVehicle: this.isHybridVehicle,
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
