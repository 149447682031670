<div class="loader__content" [ngClass]="{ 'is-hero': isHero }">
  <ng-content></ng-content>
  <div *ngIf="show" class="loader__image">
    <img
      [src]="(theme.getLoaderImagePath() | async)?.src"
      alt=""
      [ngClass]="{ animate: !(theme.getLoaderImagePath() | async)?.animated }" />
    <div class="loader__text">Loading ...</div>
  </div>
</div>
