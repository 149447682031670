import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-email-verify',
  templateUrl: './email-verify.component.html',
  styleUrls: ['./email-verify.component.scss'],
})
export class EmailVerifyComponent {
  public reactiveForm = new FormGroup({
    verificationCode: new FormControl(''),
  });
  public loading = false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  public async ngOnInit(): Promise<void> {
    try {
      await this.authService.startVerifyEmailProcess();
      this.snackBar.open('We sent you a verification email.', 'Close', {
        duration: 20000, // Duration in milliseconds
        panelClass: ['mat-toolbar', 'mat-primary'],
      });
    } catch (e) {
      const message = e instanceof Error ? e.message : 'Your email can not be verified.';
      this.snackBar.open(message, 'Close', {
        duration: 3000, // Duration in milliseconds
        panelClass: ['mat-toolbar', 'mat-warn'],
      });
    }

    this.reactiveForm = this.formBuilder.group({
      verificationCode: ['', Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.reactiveForm.controls;
  }

  public async onVerifyEmail(): Promise<void> {
    if (this.reactiveForm.invalid) return;
    this.loading = true;
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    let queryParams = {};
    if (returnUrl != '/') {
      queryParams = { queryParams: { returnUrl: returnUrl } };
    }
    try {
      const { verificationCode } = this.reactiveForm.value;
      await this.authService.verifyEmail(verificationCode!);
      const hostname = window.location.origin;
      window.location.href = hostname + returnUrl;
    } catch (error: unknown) {
      this.loading = false;
      const message = error instanceof Error ? error.message : 'Email could not be verified';
      this.snackBar.open(message, 'Close', {
        duration: 3000, // Duration in milliseconds
        panelClass: ['mat-toolbar', 'mat-warn'],
        verticalPosition: 'top',
      });
      console.error(error);
      if (message === 'Can not access user before creation.') {
        this.router.navigate(['login'], queryParams);
      }
    }
  }
}
