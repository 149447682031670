export const WHATSAPP_ICON = `
  <svg width="100%" height="100%" viewBox="0 0 506 502" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="ArtBoard1" transform="matrix(0.837748,0,0,0.933086,-316.669,-275.26)">
        <rect x="378" y="295" width="604" height="538" style="fill:none;"/>
        <g transform="matrix(1.19368,0,0,1.07171,794.543,519.23)">
            <g id="WhatsApp-Logo" serif:id="WhatsApp Logo">
                <path d="M0,83.549C-5.211,80.941 -30.831,68.336 -35.608,66.595C-40.384,64.856 -43.858,63.988 -47.332,69.203C-50.806,74.419 -60.793,86.158 -63.833,89.636C-66.872,93.113 -69.912,93.55 -75.123,90.94C-80.334,88.332 -97.125,82.83 -117.029,65.075C-132.52,51.257 -142.979,34.193 -146.018,28.976C-149.058,23.759 -146.343,20.939 -143.734,18.341C-141.389,16.006 -138.522,12.254 -135.917,9.211C-133.311,6.169 -132.443,3.994 -130.706,0.518C-128.969,-2.961 -129.837,-6.003 -131.141,-8.611C-132.443,-11.219 -142.865,-36.87 -147.207,-47.305C-151.437,-57.465 -155.733,-56.089 -158.932,-56.25C-161.968,-56.401 -165.446,-56.433 -168.919,-56.433C-172.393,-56.433 -178.039,-55.129 -182.816,-49.913C-187.592,-44.696 -201.054,-32.088 -201.054,-6.44C-201.054,19.211 -182.382,43.989 -179.776,47.467C-177.17,50.946 -143.031,103.58 -90.755,126.152C-78.323,131.522 -68.616,134.728 -61.048,137.129C-48.565,141.096 -37.205,140.536 -28.226,139.194C-18.214,137.699 2.605,126.589 6.948,114.417C11.29,102.242 11.29,91.809 9.987,89.636C8.685,87.462 5.211,86.158 0,83.549M-95.082,213.374L-95.152,213.374C-126.257,213.362 -156.765,205.005 -183.379,189.212L-189.709,185.455L-255.315,202.665L-237.804,138.699L-241.925,132.14C-259.277,104.542 -268.442,72.644 -268.428,39.89C-268.39,-55.683 -190.628,-133.439 -95.013,-133.439C-48.713,-133.423 -5.191,-115.369 27.538,-82.603C60.265,-49.839 78.278,-6.287 78.26,40.03C78.221,135.611 0.46,213.374 -95.082,213.374M52.446,-107.5C13.071,-146.921 -39.293,-168.64 -95.084,-168.663C-210.037,-168.663 -303.595,-75.111 -303.641,39.877C-303.656,76.635 -294.053,112.512 -275.802,144.141L-305.389,252.212L-194.831,223.211C-164.369,239.826 -130.072,248.584 -95.167,248.595L-95.081,248.595C19.86,248.595 113.426,155.034 113.473,40.044C113.494,-15.682 91.821,-68.08 52.446,-107.5"/>
            </g>
        </g>
    </g>
  </svg>
`;
