<button
  mat-icon-button
  [matMenuTriggerFor]="menu"
  class="social-button"
  (click)="$event.stopPropagation()"
  [ngClass]="isHero ? 'is-hero' : ''">
  <mat-icon>share</mat-icon>
</button>
<mat-menu #menu="matMenu" [class]="(theme.name | async) + '-video-menu'">
  <button (click)="copyToClipboard()" mat-menu-item>
    <mat-icon>content_copy</mat-icon>
    <span>Copy Link</span>
  </button>
  <!-- <button (click)="shareOnFacebook()" mat-menu-item>
        <mat-icon>facebook</mat-icon>
        <span>Facebook</span>
    </button> -->
  <a [href]="emailLink()" mat-menu-item>
    <mat-icon>email</mat-icon>
    <span>E-Mail</span>
  </a>
  <a [href]="whatsAppLink()" target="_blank" rel="noopener" mat-menu-item>
    <mat-icon svgIcon="whatsapp"></mat-icon>
    <span>WhatsApp</span>
  </a>
  <button (click)="shareOnLinkedIn()" mat-menu-item>
    <mat-icon svgIcon="linked-in"></mat-icon>
    <span>LinkedIn</span>
  </button>
</mat-menu>
