import {
  AuthenticationDetails,
  ChallengeName,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  IAuthenticationCallback,
} from 'amazon-cognito-identity-js';
import { environment } from 'src/environments/environment';

export interface CustomCallbacks extends IAuthenticationCallback {
  associateSecretCode: (secretCode: string) => void;
}

export enum LoginRedirectRequest {
  RequestPasswordRest,
  RequestMFASetup,
  TotpRequired,
  VerifyEmail,
}

export class CognitoAuthentication {
  private _session: CognitoUserSession | null = null;
  private _pool: CognitoUserPool | null = null;

  private authUser: CognitoUser | null = null;
  public firstLogin: boolean = false;
  private userVerified: boolean = false;
  private challangeAttr: any;
  private forgottenPasswordEmail: string;

  constructor() {}

  private createUserPool(): CognitoUserPool {
    const poolData = {
      UserPoolId: environment.cognitoConfig.userPoolId,
      ClientId: environment.cognitoConfig.clientId,
    };
    ``;
    return new CognitoUserPool(poolData);
  }

  private get pool(): CognitoUserPool {
    this._pool = this._pool ?? this.createUserPool();
    return this._pool;
  }

  private createUser(username: string): CognitoUser {
    const userData = {
      Username: username,
      Pool: this.pool,
    };

    return new CognitoUser(userData);
  }

  private get user(): CognitoUser | never {
    const user = this.authUser || this.pool.getCurrentUser();
    if (!user) {
      throw new Error('Can not access user before creation.');
    }
    return user;
  }

  private set session(session: CognitoUserSession | null) {
    this._session = session;
  }

  private get session(): CognitoUserSession | null {
    return this._session;
  }

  private getAuthenticationCallbacks(
    cognitoUser: CognitoUser,
    resolve: CallableFunction,
    reject: CallableFunction
  ): CustomCallbacks {
    this.authUser = cognitoUser;
    this.firstLogin = false;

    return {
      newPasswordRequired: userAttr => {
        const verified = userAttr?.email_verified === 'true';
        this.userVerified = verified;
        this.firstLogin = true;
        reject(LoginRedirectRequest.RequestPasswordRest);
      },

      mfaSetup: (challengeName: ChallengeName, challengeParameters: any) => {
        this.firstLogin = true;
        this.challangeAttr = challengeParameters;
        reject(LoginRedirectRequest.RequestMFASetup);
      },

      associateSecretCode: (secretCode: string) => {
        const resultQRData = `otpauth://totp/${
          location.hostname
        }: ${cognitoUser.getUsername()}?secret=${secretCode}`;
        resolve(resultQRData);
      },

      totpRequired: () => {
        reject(LoginRedirectRequest.TotpRequired);
      },

      onSuccess: async session => {
        this.handleSuccessLogin(session);
        const verified = await this.userHasVerfiedEmail();
        if (verified) {
          resolve();
        } else {
          reject(LoginRedirectRequest.VerifyEmail);
        }
      },

      onFailure: err => {
        console.error(err.message || JSON.stringify(err));
        reject(err);
      },
    };
  }

  private handleSuccessLogin(session: CognitoUserSession): void {
    this.session = session;
  }

  public async checkUserMFA(): Promise<void> {
    const mfaOptions = await new Promise<any>(resolve =>
      this.authUser.getMFAOptions((err, result) => resolve(result))
    );
    if (!mfaOptions) {
      this.firstLogin = true;
      throw LoginRedirectRequest.RequestMFASetup;
    }
  }

  public login(email: string, password: string): Promise<boolean | LoginRedirectRequest> {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const cognitoUser = this.createUser(email);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(
        authenticationDetails,
        this.getAuthenticationCallbacks(cognitoUser, resolve, reject)
      );
    });
  }
  public changeInitialPassword(newPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.user.completeNewPasswordChallenge(
        newPassword,
        {},
        this.getAuthenticationCallbacks(this.user, resolve, reject)
      );
    });
  }

  public setupMFA(): Promise<string | Error> {
    return new Promise((resolve, reject) => {
      this.user.associateSoftwareToken(this.getAuthenticationCallbacks(this.user, resolve, reject));
    });
  }

  public verifySetupMFACode(mfaCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.user.verifySoftwareToken(mfaCode, 'MFA Device', {
        onSuccess: async (session: CognitoUserSession) => {
          this.handleSuccessLogin(session);
          await this.enableMFA();
          const verified = await this.userHasVerfiedEmail();
          if (verified) {
            resolve(session);
          } else {
            reject(LoginRedirectRequest.VerifyEmail);
          }
        },

        onFailure: (err: any) => {
          console.error(err.message || JSON.stringify(err));
          reject(err);
        },
      });
    });
  }

  public sendLoginMFACode(mfaCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.user.sendMFACode(
        mfaCode,
        this.getAuthenticationCallbacks(this.user, resolve, reject),
        'SOFTWARE_TOKEN_MFA'
      );
    });
  }

  public forgotPassword(email: string): Promise<any> {
    const cognitoUser = this.createUser(email);
    this.authUser = cognitoUser;
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: () => resolve(true),
        onFailure: err => reject(err),
        inputVerificationCode: data => {
          this.forgottenPasswordEmail = data.CodeDeliveryDetails.Destination;
          resolve(true);
        },
      });
    });
  }

  public confirmNewPassword(newPassword: string, verificationCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.user.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => resolve(true),
        onFailure: err => reject(err),
      });
    });
  }

  public startVerifyEmailProcess(): Promise<any> {
    return new Promise((resolve, reject) =>
      this.user.getAttributeVerificationCode('email', {
        onSuccess: () => resolve(true),
        onFailure: err => reject(err),
        inputVerificationCode: () => resolve(true),
      })
    );
  }

  public verifyEmail(verificationCode: string): Promise<any> {
    return new Promise((resolve, reject) =>
      this.user.verifyAttribute('email', verificationCode, {
        onSuccess: () => resolve(true),
        onFailure: err => reject(err),
      })
    );
  }

  private userHasVerfiedEmail(): Promise<boolean> {
    return new Promise(resolve => {
      this.user.getUserData((err, result) => {
        const getEmailVeificationStatus = () => {
          if (err || !result) return false;
          const idx = result.UserAttributes.findIndex(el => el.Name === 'email_verified');
          if (idx === -1) return false;
          const emailVerified = result.UserAttributes[idx];
          if (emailVerified.Value !== 'true') return false;
          return true;
        };

        resolve(getEmailVeificationStatus());
      });
    });
  }

  private async enableMFA(): Promise<void> {
    const totpMfaSettings = {
      PreferredMfa: true,
      Enabled: true,
    };
    const setupMfa = new Promise<void>(resolve => {
      this.user.setUserMfaPreference(null, totpMfaSettings, (err, result) => resolve());
    });
    await setupMfa;
  }

  public clearLocalStore(): void {
    this.authUser = null;
    this.session = null;
    localStorage.clear();
  }

  public getToken(): string | undefined {
    return this.session?.getIdToken().getJwtToken();
  }
}
