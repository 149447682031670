<app-loader [show]="!loaded" [ngClass]="{ 'small-diagram': isEnergyEfficiency }">
  <img
    class="image"
    [src]="_src"
    (error)="onError()"
    (load)="onLoad()"
    draggable="false"
    (click)="showInLightbox()"
    [ngClass]="{ 'small-diagram': isEnergyEfficiency }" />
</app-loader>
