import { Component, Input } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-section-separator',
  templateUrl: './section-separator.component.html',
  styleUrls: ['./section-separator.component.scss'],
})
export class SectionSeparatorComponent {
  @Input() separatorText: string;

  constructor(public theme: ThemeService) {}
}
