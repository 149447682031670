import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { combineLatestWith, map, Subject } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ThemeService } from 'src/app/services/theme.service';
import { LightboxService } from '../lightbox/lightbox.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  @Input() set sectionIndices(indeces: Array<number>) {
    this.sectionIndices$.next(indeces);
  }

  @Input() set images(images: Array<string>) {
    this.stopNextSlideInterval();
    this.images$.next(images);
    this.nextIndex = -1;
  }

  private sectionIndices$ = new Subject<Array<number>>();
  private images$ = new Subject<Array<string>>();
  public imageSelection$ = this.images$.pipe(combineLatestWith(this.sectionIndices$)).pipe(
    map(([images, indices]) => {
      const carouselImages = [];
      for (const index of indices) {
        carouselImages.push(images[index]);
      }
      return carouselImages;
    })
  );

  public imageSelection: Array<string>;
  public currentSlide = 0;
  public nextIndex = -1;
  private nextSlideTimer: ReturnType<typeof setInterval>;

  public slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '6.5%',
    arrows: false,
    touchThreshold: 7,
    touchMove: true,
    infinite: true,
  };

  constructor(
    public theme: ThemeService,
    private api: ApiService,
    public lightbox: LightboxService
  ) {
    this.imageSelection$.subscribe(data => {
      this.imageSelection = data;
    });
  }

  public onInit(): void {
    this.resetNextSlideInterval();
  }

  public onBeforeChange(slick): void {
    this.stopNextSlideInterval();
    let nextIndex = slick.currentSlide + 1; // assume moving right
    if (
      slick.currentSlide - 1 == slick.nextSlide ||
      (slick.nextSlide + 1 == slick.slick.slideCount && slick.currentSlide < slick.nextSlide)
    ) {
      nextIndex = slick.currentSlide - 1; // nope, moving left
    }
    this.nextIndex = nextIndex;
  }

  public onAfterChange(slick): void {
    this.currentSlide = slick.currentSlide;
    this.nextIndex = -1;
    this.resetNextSlideInterval();
  }

  public onLoad(src: string): void {
    this.api.onAssetLoadFinished(src);
  }

  public trackByFn(index, item) {
    return index;
  }

  private resetNextSlideInterval(time: number = 10000): void {
    if (this.nextSlideTimer !== undefined) {
      this.stopNextSlideInterval();
    }

    this.nextSlideTimer = setInterval(() => {
      // nxg slider problem, it will fire update before init complete
      // to avoid error $instance undefind will be catched
      if (!this.slickModal.$instance) return;
      this.slickModal.slickNext();
    }, time);
  }

  private stopNextSlideInterval(): void {
    if (this.nextSlideTimer !== undefined) {
      clearInterval(this.nextSlideTimer);
      this.nextSlideTimer = undefined;
    }
  }
}
