<app-loader [show]="!loaded" [isHero]="isHero">
  <div
    #videoContainer
    class="video"
    [ngClass]="(isHero ? 'is-hero' : '') + ' ' + (isDrivingExp ? '' : 'non-driving-experience')">
    <!-- <div *ngIf="isHero" [ngClass]="(!paused ? 'video-is-playing' : '')" [class]="(theme.name | async) + '-model-header model-header'">
            <ng-container *ngFor="let line of modelName; let i = index">
                <h1 [innerHTML]="line"></h1>
            </ng-container>
        </div> -->

    <video
      #video
      preload="auto"
      *ngIf="src"
      [src]="src + '#t=0.01'"
      (ended)="videoEnded()"
      (pause)="videoPaused($event)"
      (play)="videoPlayed($event)"
      (loadeddata)="onLoad()"
      (timeupdate)="videoTimeUpdate()"
      (progress)="progress($event)"
      (error)="onError()"
      [ngStyle]="{
        top: videoTranslate.top,
        transform: 'translate3d(' + videoTranslate.x + ', ' + videoTranslate.y + ', 0 )'
      }"></video>
    <div
      *ngIf="loaded && !isBrowserMozilla"
      class="video__controls"
      (mousemove)="onMouseMove()"
      (click)="playPause()"
      [ngClass]="isHero ? 'is-hero' : ''">
      <button
        *ngIf="!videoStarted && paused"
        [ngClass]="(theme.name | async) + '-video-play-button play-pause-button'"></button>

      <button
        mat-icon-button
        [matMenuTriggerFor]="menuEnv"
        class="more-button env-button"
        [ngClass]="isHero ? 'is-hero' : ''"
        (click)="$event.stopPropagation()">
        <mat-icon>landscape</mat-icon>
      </button>
      <mat-menu #menuEnv="matMenu" [class]="(theme.name | async) + '-env-menu'">
        <button mat-menu-item *ngFor="let env of availableEnvs" (click)="changeEnv(env)">
          <span
            [class]="(theme.name | async) + '-env-menu-element'"
            [ngClass]="{
              active: env.environment === activeEnv.environment && env.daytime === activeEnv.daytime
            }"
            >{{ env.environment }} | {{ env.daytime }}</span
          >
        </button>
      </mat-menu>

      <app-social [isHero]="isHero" [videoId]="videoId"></app-social>
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        class="more-button"
        (click)="$event.stopPropagation()"
        [ngClass]="isHero ? 'is-hero' : ''">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" [class]="(theme.name | async) + '-video-menu'">
        <button mat-menu-item (click)="replayVideo()">
          <mat-icon>replay</mat-icon>
          <span>Replay video</span>
        </button>
        <button
          mat-menu-item
          *ngIf="!prodMode"
          (click)="downloadFile(src, firstPartOfFilename + fileNameExtension)">
          <mat-icon>file_download</mat-icon>
          <span>Download video</span>
        </button>
        <a
          *ngIf="prodMode"
          #blobDownload
          [download]="firstPartOfFilename + fileNameExtension"
          [href]="src"
          mat-menu-item>
          <mat-icon>file_download</mat-icon>
          <span>Download video</span>
        </a>
      </mat-menu>

      <div
        class="video__controls__container"
        [ngClass]="{ 'not-hero-video ': !isHero }"
        *ngIf="videoStarted && showVideoTransportControls && (!_isMobileDevice() || !_isiOS())">
        <div class="video__controls__container__flex left">
          <button
            [class]="(theme.name | async) + '-volume volume ' + (isHero ? 'is-hero ' : '')"
            (click)="replayVideo()">
            <mat-icon>replay</mat-icon>
          </button>
        </div>

        <button
          *ngIf="paused"
          [ngClass]="(theme.name | async) + '-video-play-button play-pause-button'"></button>
        <button
          *ngIf="!paused"
          [ngClass]="(theme.name | async) + '-video-pause-button play-pause-button'"></button>

        <div class="video__controls__container__flex">
          <button
            [class]="
              (theme.name | async) +
              '-volume volume ' +
              (isHero ? 'is-hero ' : '') +
              (hover ? 'volume-slider--show' : 'volume-slider--hide')
            "
            (mouseenter)="hover = true"
            (mouseleave)="hover = false">
            <mat-icon (click)="toggleVideoMute($event)">
              {{ videoCurrentVolume > 0 ? 'volume_up' : 'volume_off' }}
            </mat-icon>
            <mat-slider min="0" max="1" step="0.05" (click)="$event.stopPropagation()">
              <input
                matSliderThumb
                [value]="videoCurrentVolume"
                (valueChange)="videoCurrentVolume = $event" />
            </mat-slider>
          </button>
        </div>

        <div [class]="(theme.name | async) + '-timeline timeline'" (click)="skipInVideo($event)">
          <progress id="progress" [value]="videoCurrentTime" [max]="videoDuration">
            <span id="progress-bar"></span>
          </progress>
        </div>
      </div>
    </div>

    <div
      *ngIf="isHero"
      [ngClass]="!paused ? 'video-is-playing' : ''"
      [class]="(theme.name | async) + '-scroll-indicator scroll-indicator'">
      <div class="border-grey"></div>
      <div class="border"></div>
    </div>
  </div>
</app-loader>
