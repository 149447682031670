import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginRedirectRequest } from 'src/app/utils/auth';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordValidation } from 'src/app/utils/validations';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent implements OnInit {
  public submitted = false;
  public loading = false;
  public reactiveForm = new FormGroup({
    newPassword: new FormControl(''),
    confirmPassword: new FormControl(''),
  });

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  public async ngOnInit(): Promise<void> {
    this.reactiveForm = this.formBuilder.group(
      {
        newPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(40)]],
        confirmPassword: ['', Validators.required],
      },
      {
        validators: [PasswordValidation.match('newPassword', 'confirmPassword')],
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.reactiveForm.controls;
  }

  public async onPasswordRest(): Promise<void> {
    this.submitted = true;
    if (this.reactiveForm.invalid) return;
    this.loading = true;
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    let queryParams = {};
    if (returnUrl != '/') {
      queryParams = { queryParams: { returnUrl: returnUrl } };
    }
    try {
      const { newPassword } = this.reactiveForm.value;
      await this.authService.changeInitialPassword(newPassword!);
      const hostname = window.location.origin;
      window.location.href = hostname + returnUrl;
    } catch (error: unknown) {
      if (error === LoginRedirectRequest.RequestMFASetup) {
        this.router.navigate(['login', 'mfa'], queryParams);
      } else if (error === LoginRedirectRequest.VerifyEmail) {
        this.router.navigate(['login', 'verify'], queryParams);
      } else {
        this.loading = false;
        const resetError = error instanceof Error ? error.message : 'Password can not be changed';
        console.error(resetError);
        this.snackBar.open(resetError, 'Close', {
          duration: 3000, // Duration in milliseconds
          panelClass: ['mat-toolbar', 'mat-warn'], // Optional: add custom classes for styling
          verticalPosition: 'top',
        });
        if (resetError === 'Can not access user before creation.') {
          this.router.navigate(['login'], queryParams);
        }
      }
    }
  }
}
