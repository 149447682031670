import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  constructor() {}

  public shareOnFacebook(videoId: string): void {
    FB.ui(
      {
        method: 'share',
        href: this.generateUrlToShare(videoId),
      },
      response => {
        console.warn('FB response', response);
      }
    );
  }

  public shareOnLinkedIn(videoId: string): void {
    const urlToShare = this.generateUrlToShare(videoId);
    this.openNewWindow(
      `https://www.linkedin.com/shareArticle?mini=true&url=${urlToShare}&title=${this.generateSubject(
        videoId
      )}`
    );
  }

  public async copyToClipboard(videoId: string): Promise<void> {
    const urlToShare = this.generateUrlToShare(videoId);
    await navigator.clipboard.writeText(urlToShare);
  }

  public generateEmailLink(videoId: string): string {
    const urlToShare = this.generateUrlToShare(videoId);
    return `mailto:?body=${encodeURIComponent(urlToShare)}&subject=${this.generateSubject(
      videoId
    )}`;
  }

  public generateWhatsAppShareLink(videoId: string): string {
    const urlToShare = this.generateUrlToShare(videoId);
    return `https://wa.me/?text=${encodeURIComponent(
      this.generateSubject(videoId) + ': ' + urlToShare
    )}`;
  }

  private generateSubject(videoId: string): string {
    const subject = `Take a look at my vehicle - ${this.capitalizeFirstLetter(videoId)} Video`;
    return subject;
  }

  private generateUrlToShare(videoId: string): string {
    const currentUrl = window.location.toString().split('#')[0];
    if (!videoId || videoId === 'Emotional Drive') {
      return currentUrl;
    }
    return currentUrl + '#' + videoId.toLocaleLowerCase();
  }

  private openNewWindow(url: string): void {
    window.open(
      url,
      'targetWindow',
      `toolbar=no,
     location=no,
     status=no,
     menubar=no,
     scrollbars=yes,
     resizable=yes,
     width=400,
     height=400`
    );
  }

  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
