import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, forkJoin, throwError, EMPTY } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StructureService } from './structure.service';
import { ThemeService } from './theme.service';
import { TranslationService } from './translation.service';
import { IAssetUrls, IBrochureAssetResponse } from '../interfaces/api';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  /**
   * publish loading state and error
   */
  public loading$ = new BehaviorSubject<boolean>(true);
  public loadingError$ = new BehaviorSubject<boolean>(false);
  public loadingTimeout$ = new Subject<void>();

  /**
   * publish model and video spesific data
   */
  public modelName = new BehaviorSubject<string>('');
  public modelRange = new BehaviorSubject<string>('');
  public videoPositions: Observable<{ [id: string]: number }>;

  private assetsLoaded: string[] = [];
  private assetsToLoad: number = 0;
  private loadingTimeout: any;

  constructor(
    private http: HttpClient,
    private theme: ThemeService,
    private translation: TranslationService,
    private structureService: StructureService,
    private router: Router
  ) {
    this.videoPositions = this.http.get<{ [id: string]: number }>('/assets/video-positioning.json');
  }

  /**
   * Get asset urls of a brochure
   * @param configId Brichure ID
   * @returns
   */
  public getAssetUrls(configId: string): Observable<IAssetUrls> {
    this.theme.applyBodyFixClass();
    if (!configId || configId === '' || configId === 'add-your-code-here') {
      return throwError(
        new HttpErrorResponse({
          error: 'ConfigId was not provided.',
          status: 400,
        })
      );
    }

    return forkJoin([
      this.http.get<IBrochureAssetResponse | any>(
        `${environment.apiUrl}${environment.getAssetsEndpoint}${configId}`,
        {
          headers: {
            clientID: 'BMW-digitalbrochure-frontend',
          },
        }
      ),
    ]).pipe(
      map(([res]) => res),
      tap((res: IBrochureAssetResponse) => console.log('[API RESPONSE]', res)),
      tap((res: IBrochureAssetResponse) => {
        if (!environment.production) {
          this.verifyAuthorization(res);
        }
      }),
      tap((res: IBrochureAssetResponse) =>
        // Activate theme basend on brand
        this.theme.setTheme(
          res.configuration.brand,
          res.configuration.market,
          res.configuration.language,
          res.configuration.modelRange
        )
      ),
      tap((res: IBrochureAssetResponse) =>
        this.modelName.next(
          this.translation.getModelName(
            res.configuration.brand,
            res.configuration.modelRange,
            res.configuration.agModelCode
          )
        )
      ),
      tap((res: IBrochureAssetResponse) => this.modelRange.next(res.configuration.modelRange)),
      tap((res: IBrochureAssetResponse) => this.structureService.setActiveBrochure(res)),
      map((res: IBrochureAssetResponse) => {
        if (!!res.emission) {
          this.theme.setEmission(res.emission);
        }

        return {
          images: res.resources.images,
          videos: res.resources.videos,
        };
      })
    );
  }

  /**
   * Get current model name
   * @returns
   */
  public getModelName() {
    return this.modelName;
  }

  /**
   * Function to complete loading
   * @param url
   */
  public onAssetLoadFinished(url: string): void {
    this.assetsLoaded.push(url);
    const totalLoadedAssetCount = this.assetsLoaded.length;
    console.log('[LOADING STATUS]', `${totalLoadedAssetCount}/${this.assetsToLoad}`);
    if (totalLoadedAssetCount >= this.assetsToLoad) {
      clearTimeout(this.loadingTimeout);
      this.theme.removeBodyFixClass();
      this.loading$.next(false);
      console.log('[LOADING COMPLETE]');
    }
  }

  /**
   * Function to set error in app
   * @param url resource which has not been loaded
   */
  public onAssetLoadFailed(url: string): void {
    this.assetsLoaded.push(url);
    clearTimeout(this.loadingTimeout);
    this.loadingError$.next(true);
    this.loading$.next(false);
  }

  /**
   * Increase the amount of resources that are currently loading.
   * Just for displaying loading screen correct.
   * @param assetCount
   */
  public increaseAssetCount(assetCount: number): void {
    this.assetsToLoad = this.assetsToLoad + assetCount;
    this.setLoadingTimeout();
  }

  private setLoadingTimeout(): void {
    clearTimeout(this.loadingTimeout);
    this.loadingTimeout = setTimeout(() => {
      this.loadingTimeout$.next();
    }, 150000);
  }

  public verifyAuthorization(res: IBrochureAssetResponse): void {
    const url = '/trunks/' + res.trunk + '/auth_verification'; // just non existing key to verify auth of s3
    this.http
      .get<any>(url)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // Only handle 401 Unauthorized error
            console.log('Unauthorized Access, redirected to login:', error.message);
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
          }
          // Return an empty observable to ignore other errors
          return EMPTY;
        })
      )
      .subscribe();
  }
}
