<div class="modal-container">
  <div class="close-icon" mat-dialog-close></div>

  <h3>CO2-EFFIZIENZ (NEFZ)</h3>

  <div class="col-value-table">
    <div class="row">
      <div class="col">
        <strong>{{ formattedEmissionData.nefz[0].header }}</strong>
      </div>
      <div class="col">
        <strong>{{ formattedEmissionData.nefz[1].header }}</strong>
      </div>
      <div class="col" *ngIf="data.isHybridVehicle">
        <strong>{{ formattedEmissionData.nefz[2].header }}</strong>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>{{ formattedEmissionData.nefz[0].consumption }}</p>
      </div>
      <div class="col">
        <p>{{ formattedEmissionData.nefz[1].emissionCombined }}</p>
      </div>
      <div class="col" *ngIf="data.isHybridVehicle">
        <p>{{ formattedEmissionData.nefz[2].hybridElectricConsumption }}</p>
      </div>
    </div>
  </div>

  <div class="separator"></div>

  <h3>CO2-EFFIZIENZKLASSE</h3>

  <div class="efficiency-grid">
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 0; fill: #00a54f">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 0">
        <text class="text" x="5px" y="50%">
          A
          <tspan class="text-sup" dy="-25%">+++</tspan>
        </text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 1; fill: #00a54f">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 1">
        <text class="text" x="5px" y="50%">
          A
          <tspan class="text-sup" dy="-25%">++</tspan>
        </text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 2; fill: #4cb848">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 2">
        <text class="text" x="5px" y="50%">
          A
          <tspan class="text-sup" dy="-25%">+</tspan>
        </text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 3; fill: #bed630">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 3">
        <text class="text" x="5px" y="50%">A</text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 4; fill: #bed630">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 4">
        <text class="text" x="5px" y="50%">B</text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 5; fill: #fff101">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 5">
        <text class="text" x="5px" y="50%">C</text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 6; fill: #f36e21">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 6">
        <text class="text" x="5px" y="50%">D</text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 7; fill: #ee1d23">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 7">
        <text class="text" x="5px" y="50%">E</text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 8; fill: #ee1d23">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 8">
        <text class="text" x="5px" y="50%">F</text>
      </svg>
    </div>
    <div class="efficiency-class-holder">
      <svg
        height="100%"
        viewBox="0 0 125 50"
        class="efficiency-class"
        preserveAspectRatio="xMaxYMid slice"
        style="--class-index: 9; fill: #ee1d23">
        <path d="M -10000 0 h 10100 l 25 25 l -25 25 h -10100 Z" stroke="none"></path>
      </svg>
      <svg class="efficiency-class-text" style="--class-index: 9">
        <text class="text" x="5px" y="50%">G</text>
      </svg>
    </div>

    <div
      class="selected-efficiency"
      [ngStyle]="{ 'grid-row': efficiencyCategoryIndex + ' / auto' }">
      <svg
        viewBox="0 0 44 20"
        preserveAspectRatio="xMinYMid slice"
        class="selected-efficiency-class">
        <polygon points="1,10 10,19 43,19 43,1 10,1" stroke="black" stroke-width="2"></polygon>
      </svg>
      <svg class="selected-efficiency-class-text">
        <text class="text" x="50%" y="50%">
          {{ data.emissionData.efficiencyCategory.toUpperCase() }}
        </text>
      </svg>
    </div>
  </div>

  <h3 style="margin-bottom: 20px">CO2-EFFIZIENZ NACH DEM WLTP MESSVERFAHREN.</h3>
  <h4>WAS IST WLTP?</h4>
  <h4>DIESER WERT GILT AB DEM 01.01.2019</h4>
  <p>
    Seit dem 1. September 2017 werden bestimmte Neuwagen nach dem weltweit harmonisierten
    Prüfverfahren für Personenwagen und leichte Nutzfahrzeuge (Worldwide Harmonized Light Duty
    Vehicles Test Procedure, WLTP), einem realistischeren Prüfverfahren zur Messung des
    Kraftstoffverbrauchs und der CO2-Emissionen, typgenehmigt. Ab dem 1. September 2018 wird der
    WLTP den neuen europäischen Fahrzyklus (NEFZ) ersetzen. Wegen der realistischeren
    Prüfbedingungen sind die nach dem WLTP gemessenen Kraftstoffverbrauchs-, Stromverbrauchs- und
    CO2-Emissionswerte in vielen Fällen höher und die Reichweiten in vielen Fällen niedriger als die
    nach dem NEFZ gemessenen. Aktuell sind noch die NEFZ-Werte verpflichtend zu kommunizieren.
    Soweit es sich um Neuwagen handelt, die nach WLTP typgenehmigt sind, werden die o.g. NEFZ-Werte
    von den u.g. WLTP-Werten abgeleitet. Diese Werte entsprechen Ihrer aktuellen Konfiguration, wenn
    Sie Ausstattungen ändern können sich auch diese Werte ändern. Ab dem 01. September 2018 erfolgt
    die Bemessung der KFZ-Steuer auf Basis der ermittelten WLTP-Werte. Weitere Informationen zum
    Umweltbonus und E-Kennzeichen erhalten Sie bei Ihrem BMW Partner.
  </p>

  <ng-container *ngIf="data.isHybridVehicle">
    <h3 style="margin-top: 45px">CO2-EMISSION NACH DEM WLTP MESSVERFAHREN</h3>
    <div class="value-row">
      <strong>{{ formattedEmissionData.wltp[2].header }}</strong>
      <p>{{ formattedEmissionData.wltp[2].hybridFuelConsumptionCombined }}</p>
    </div>

    <div class="separator"></div>

    <h3 style="margin-top: 45px">KRAFTSTOFFVERBRAUCH (WLTP)</h3>
    <div class="value-row">
      <strong>{{ formattedEmissionData.wltp[3].header }}</strong>
      <p>{{ formattedEmissionData.wltp[3].hybridCo2Combined }}</p>
    </div>

    <div class="separator"></div>
  </ng-container>

  <h3 style="margin-top: 45px">
    {{
      data.isElectricVehicle ? 'STROMVERBRAUCH (WLTP)' : 'CO2-EMISSION NACH DEM WLTP MESSVERFAHREN'
    }}
  </h3>
  <div class="value-row">
    <strong>{{ this.formattedEmissionData.wltp[0].header }}</strong>
    <p>{{ formattedEmissionData.wltp[0].emissionCombined }}</p>
  </div>

  <div class="separator"></div>

  <h3 style="margin-top: 45px">
    {{ data.isElectricVehicle ? 'ELEKTRISCHE REICHWEITE (WLTP)' : 'KRAFTSTOFFVERBRAUCH (WLTP)' }}
  </h3>
  <div class="value-row">
    <strong>{{ formattedEmissionData.wltp[1].header }}</strong>
    <p>{{ formattedEmissionData.wltp[1].consumptionCombined }}</p>
  </div>

  <div class="separator"></div>

  <p class="footer-notice">
    Seit dem 1. September 2017 werden bestimmte Neuwagen nach dem weltweit harmonisierten
    Prüfverfahren für Personenwagen und leichte Nutzfahrzeuge (Worldwide Harmonized Light Duty
    Vehicles Test Procedure, WLTP), einem realistischeren Prüfverfahren zur Messung des
    Kraftstoffverbrauchs und der CO2-Emissionen, typgenehmigt. Ab dem 1. September 2018 wird der
    WLTP den neuen europäischen Fahrzyklus (NEFZ) ersetzen. Wegen der realistischeren
    Prüfbedingungen sind die nach dem WLTP gemessenen Kraftstoffverbrauchs-, Stromverbrauchs- und
    CO2-Emissionswerte in vielen Fällen höher und die Reichweiten in vielen Fällen niedriger als die
    nach dem NEFZ gemessenen. Aktuell sind noch die NEFZ-Werte verpflichtend zu kommunizieren.
    Soweit es sich um Neuwagen handelt, die nach WLTP typgenehmigt sind, werden die o.g. NEFZ-Werte
    von den u.g. WLTP-Werten abgeleitet. Diese Werte entsprechen Ihrer aktuellen Konfiguration, wenn
    Sie Ausstattungen ändern können sich auch diese Werte ändern. Ab dem 01. September 2018 erfolgt
    die Bemessung der KFZ-Steuer auf Basis der ermittelten WLTP-Werte. Weitere Informationen zum
    Umweltbonus und E-Kennzeichen erhalten Sie bei Ihrem BMW Partner.
  </p>
</div>
