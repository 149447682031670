<mat-card>
  <mat-card-content
    ><form class="login" [formGroup]="reactiveForm" (ngSubmit)="onVerifyEmail()">
      <h3>Verify your email</h3>
      <mat-label>Please enter verification code from your verification email</mat-label>
      <mat-form-field>
        <input
          matInput
          id="verificationCode"
          type="text"
          name="verification code"
          placeholder="verification code"
          formControlName="verificationCode"
          required />
      </mat-form-field>

      <button mat-button color="primary">
        <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">Confirm and back to login</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>
