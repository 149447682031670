import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SocialService } from 'src/app/services/social.service';
import { ThemeService } from 'src/app/services/theme.service';
import { LINKEDIN_ICON } from './icons/linkedin.icon';
import { TWITTER_ICON } from './icons/twitter.icon';
import { WHATSAPP_ICON } from './icons/whatsapp.icon';

@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss'],
})
export class SocialComponent {
  @Input() isHero: boolean = false;
  @Input() videoId: string;

  constructor(
    private social: SocialService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public theme: ThemeService
  ) {
    iconRegistry.addSvgIconLiteral('linked-in', sanitizer.bypassSecurityTrustHtml(LINKEDIN_ICON));
    iconRegistry.addSvgIconLiteral('twitter', sanitizer.bypassSecurityTrustHtml(TWITTER_ICON));
    iconRegistry.addSvgIconLiteral('whatsapp', sanitizer.bypassSecurityTrustHtml(WHATSAPP_ICON));
  }

  public shareOnFacebook(): void {
    this.social.shareOnFacebook(this.videoId);
  }

  public shareOnLinkedIn(): void {
    this.social.shareOnLinkedIn(this.videoId);
  }

  public emailLink(): string {
    return this.social.generateEmailLink(this.videoId);
  }

  public whatsAppLink(): string {
    return this.social.generateWhatsAppShareLink(this.videoId);
  }

  public copyToClipboard(): void {
    this.social.copyToClipboard(this.videoId);
  }
}
