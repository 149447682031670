<mat-card>
  <mat-card-content>
    <mat-tab-group dynamicHeight>
      <!-- First Tab -->
      <mat-tab label="Legacy">
        <form #loginFormLegacy="ngForm" (ngSubmit)="onSubmitLegacy(loginFormLegacy)">
          <h2>Basic Auth Log In</h2>
          <mat-label
            >Basic auth will be disabled in the future, <br />please
            <a [href]="applyCognito">apply</a> a Cognito account
          </mat-label>
          <mat-form-field>
            <input matInput placeholder="Username" name="username" ngModel required />
            <mat-error> Please provide a valid email address </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="password"
              placeholder="Password"
              name="password"
              ngModel
              required />
            <mat-error> Please provide a valid password </mat-error>
          </mat-form-field>
          <button
            mat-button
            matTooltip="Basic auth will be disabled in the future, please apply a Cognito account instead"
            mat-raised-button
            color="primary"
            matTooltipClass="warning-tooltip"
            [disabled]="!loginFormLegacy.form.valid">
            Login
          </button>
        </form>
      </mat-tab>
      <mat-tab label="Cognito">
        <form #loginFormMFA="ngForm" (ngSubmit)="onSubmitMFA(loginFormMFA)">
          <h2>MFA Log In</h2>
          <mat-label
            >To apply a new Cognito account, <br />please click
            <a [href]="applyCognito">here</a>
          </mat-label>
          <mat-form-field>
            <input matInput placeholder="Email" name="email" ngModel required />
            <mat-error> Please provide a valid email address </mat-error>
          </mat-form-field>
          <mat-form-field>
            <input
              matInput
              type="password"
              placeholder="Password"
              name="password"
              ngModel
              required />
            <mat-error> Please provide a valid password </mat-error>
          </mat-form-field>
          <button mat-button color="primary" [disabled]="!loginFormMFA.form.valid">
            <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
            <span *ngIf="!loading">Login</span>
          </button>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
