<mat-card>
  <mat-card-content>
    <form class="login" [formGroup]="reactiveForm" (ngSubmit)="onPasswordRest()">
      <mat-label>Please change your initial password</mat-label>
      <mat-form-field>
        <input
          matInput
          id="newPassword"
          type="password"
          name="newPassword"
          placeholder="New Password"
          formControlName="newPassword"
          required />
        <mat-error *ngIf="submitted && f['newPassword'].errors as errors">
          <span *ngIf="errors['required']"> Password is required </span>
          <span *ngIf="errors['minlength']"> Password must be at least 6 characters </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          id="confirmPassword"
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          formControlName="confirmPassword"
          required />
        <mat-error *ngIf="submitted && f['confirmPassword'].errors as errors">
          Passwords do not match
        </mat-error>
      </mat-form-field>
      <button mat-button color="primary">
        <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">Confirm</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>
