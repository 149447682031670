import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  public size$: BehaviorSubject<[number, number]>;

  constructor() {
    this.size$ = new BehaviorSubject([window.innerWidth, window.innerHeight]);

    window.addEventListener('resize', () => {
      this.size$.next([window.innerWidth, window.innerHeight]);
    });
  }
}
