import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { IEmission } from '../interfaces/api';
import { IMarketLanguageMapping } from '../interfaces/ui';

export interface ThemeConfig {
  imprint_link: string;
  imprint_linktext: string;
  copyright: string;
  disclaimer: {
    title: string;
    text: string;
  };
  loader: {
    name: string;
    animated: boolean;
  };
}

export interface ThemeData {}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private availableLanguages: Array<string>;
  private emission: BehaviorSubject<IEmission> = new BehaviorSubject(null);

  public state: ReplaySubject<{ name: string; market: string; language: string }> =
    new ReplaySubject<{ name: string; market: string; language: string }>(1);
  public config: BehaviorSubject<ThemeConfig> = new BehaviorSubject(null);
  public data: BehaviorSubject<ThemeData> = new BehaviorSubject(null);
  public name: Observable<string> = this.state.pipe(
    filter(p => !!p?.name),
    map(p => p.name)
  );
  defaultSubscriber = {
    next: data => void 0,
    error: err => void 0,
    complete: () => void 0,
  };
  public theme: { name: string; market: string; language: string };
  private renderer: Renderer2;

  constructor(
    private http: HttpClient,
    @Inject(DOCUMENT) private document: HTMLDocument,
    rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.state
      .pipe(
        filter(({ name, market, language }) => !!name),
        tap(({ name, market, language }) => {
          this.renderer.setAttribute(
            document.getElementById('favicon'),
            'href',
            `/assets/themes/${name}/favicon.ico`
          );
          document.title = name.toUpperCase() + ' – Digital Brochure';
          this.renderer.addClass(document.body, `${name.toLocaleLowerCase()}-body`);
        }),
        switchMap(({ name, market, language }) => this.loadConfig(name))
      )
      .subscribe(this.defaultSubscriber);

    this.state
      .pipe(
        filter(({ name, market, language }) => !!name),
        switchMap(({ name, market, language }) => this.loadData(name, market, language))
      )
      .subscribe(this.defaultSubscriber);
  }

  public setTheme(
    name: string,
    market: string = 'en',
    language: string = 'en',
    modelRange: string
  ): void {
    const brand = name === 'bmwi' || name === 'bmwcar' ? 'bmw' : name.toLocaleLowerCase();

    this.theme = {
      name: brand,
      market: market.toLocaleLowerCase(),
      language: language.toLocaleLowerCase(),
    };

    this.state.next({
      name: brand,
      market: market.toLocaleLowerCase(),
      language: language.toLocaleLowerCase(),
    });
  }

  public getAvailableLanguages(): Array<string> {
    return this.availableLanguages;
  }

  public setLanguage(language): void {
    this.theme.language = language;

    this.state.next({
      name: this.theme.name,
      market: this.theme.market,
      language: language.toLocaleLowerCase(),
    });
  }

  public getLoaderImagePath(): Observable<{ src: string; animated: boolean }> {
    return this.config.pipe(
      filter(config => !!config && !!this.state),
      withLatestFrom(this.state),
      filter(([config, state]) => !!state.name),
      map(([config, state]) => ({
        src: `/assets/themes/${state.name}/icons/${config.loader.name}`,
        animated: config.loader.animated,
      }))
    );
  }

  public setEmission(emission: IEmission) {
    this.state
      .pipe(
        filter(({ name, market, language }) => !!market),
        tap(({ name, market, language }) => {
          if (market === 'de') {
            this.emission.next(emission);
          }
        })
      )
      .subscribe(this.defaultSubscriber);
  }

  public getEmission() {
    return this.emission;
  }

  private async loadConfig(name: string): Promise<void> {
    const config = await this.http
      .get<ThemeConfig>(`assets/themes/${name}/config.json`)
      .toPromise();
    this.config.next(config);
  }

  private async loadData(name: string, market: string, language: string): Promise<void> {
    const mm = market.toLocaleLowerCase();
    const ll = language.toLocaleLowerCase();
    const fileName = `${mm}_${ll}`;

    const marketsMapping: IMarketLanguageMapping = await this.http
      .get<any>(`assets/themes/${name}/translations/market-language-mapping.json`)
      .toPromise();
    this.availableLanguages = !!marketsMapping[mm]
      ? marketsMapping[mm].languages
      : marketsMapping['en'].languages;

    console.log('[AVAILABLE LANGUAGES]', this.availableLanguages);

    const data = await this.http
      .get<ThemeConfig>(`assets/themes/${name}/translations/${fileName}.json`)
      .pipe(
        catchError(err => {
          this.setLanguage(marketsMapping[mm].default);
          return this.http.get<ThemeConfig>(
            `assets/themes/${name}/translations/${mm}_${marketsMapping[mm].default}.json`
          );
        }),
        catchError(err =>
          this.http.get<ThemeConfig>(`assets/themes/${name}/translations/default.json`)
        )
      )
      .toPromise();

    this.data.next(data);
  }

  public applyBodyFixClass(): void {
    this.renderer.addClass(this.document.body, 'fix');
  }

  public removeBodyFixClass(): void {
    this.renderer.removeClass(this.document.body, 'fix');
  }
}
