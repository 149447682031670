import { Component, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { LightboxService } from '../lightbox/lightbox.service';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  public _src: string;
  public loaded: boolean;

  @Input() public set src(url: string) {
    if (url) {
      this._src = url;
    }
  }
  public get src(): string {
    return this._src;
  }

  @Input() isEnergyEfficiency: boolean = false;

  constructor(private api: ApiService, private lightbox: LightboxService) {}

  public onLoad(): void {
    this.api.onAssetLoadFinished(this._src);
    this.loaded = true;
  }

  public onError(): void {
    this.api.onAssetLoadFailed(this._src);
  }

  public showInLightbox(): void {
    this.lightbox.open(this.src);
  }
}
