import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { ThemeService } from 'src/app/services/theme.service';

@Injectable({
  providedIn: 'root',
})
export class LightboxService {
  public assetUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public show$: Observable<boolean>;
  public assetType: 'image' = 'image';

  constructor(private themeService: ThemeService) {
    this.show$ = this.assetUrl$.pipe(
      delay(100),
      map(url => !!url)
    );
  }

  open(assetUrl: string): void {
    this.assetUrl$.next(assetUrl);
    this.themeService.applyBodyFixClass();
  }

  close(): void {
    this.assetUrl$.next(null);
    this.themeService.removeBodyFixClass();
  }
}
