import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IEmissionsDialogData } from 'src/app/interfaces/emission';

export interface ModalEmissionFormatted {
  nefz: [
    {
      header: string;
      consumption: string;
    },
    {
      header: string;
      emissionCombined: string;
    },
    {
      header: string;
      hybridElectricConsumption: string;
    }
  ];
  wltp: [
    {
      header: string;
      emissionCombined: string;
    },
    {
      header: string;
      consumptionCombined: string;
    },
    {
      header: string;
      hybridFuelConsumptionCombined: string;
    },
    {
      header: string;
      hybridCo2Combined: string;
    }
  ];
}

@Component({
  selector: 'app-emissions-modal',
  templateUrl: './emissions-modal.component.html',
  styleUrls: ['./emissions-modal.component.scss'],
})
export class EmissionsModalComponent {
  public formattedEmissionData: ModalEmissionFormatted = {
    nefz: [
      {
        header: '',
        consumption: '',
      },
      {
        header: '',
        emissionCombined: '',
      },
      {
        header: '',
        hybridElectricConsumption: '',
      },
    ],
    wltp: [
      {
        header: '',
        emissionCombined: '',
      },
      {
        header: '',
        consumptionCombined: '',
      },
      {
        header: '',
        hybridFuelConsumptionCombined: '',
      },
      {
        header: '',
        hybridCo2Combined: '',
      },
    ],
  };

  private efficiencyCategoryMapping = {
    'a+++': 1,
    'a++': 2,
    'a+': 3,
    a: 4,
    b: 5,
    c: 6,
    d: 7,
    e: 8,
    f: 9,
    g: 10,
  };

  public efficiencyCategoryIndex: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IEmissionsDialogData) {
    const emission = data.emissionData;

    if (!!emission) {
      if (data.isElectricVehicle) {
        this.formattedEmissionData.nefz[0].header = 'STROMVERBRAUCH (NEFZ)';
        this.formattedEmissionData.nefz[0].consumption = `${emission.nedcCorrelated.electricConsumptionCombined} kWh/100km`;
        this.formattedEmissionData.nefz[1].header = 'ELEKTRISCHE REICHWEITE (NEFZ)';
        this.formattedEmissionData.nefz[1].emissionCombined = `${emission.nedcCorrelated.pureElectricRangeCombined} km`;

        this.formattedEmissionData.wltp[0].header = 'STROMVERBRAUCH (WLTP)';
        this.formattedEmissionData.wltp[0].emissionCombined = `${emission.wltp.electricConsumptionCombined} kWh/100km`;
        this.formattedEmissionData.wltp[1].header = 'ELEKTRISCHE REICHWEITE (WLTP)';
        this.formattedEmissionData.wltp[1].consumptionCombined = `${emission.wltp.pureElectricRangeCombined} km`;

        if (data.isHybridVehicle) {
          this.formattedEmissionData.nefz[2].header = 'STROMVERBRAUCH (NEFZ)';
          this.formattedEmissionData.nefz[2].hybridElectricConsumption = `${emission.nedcCorrelated.electricConsumptionCombined} kWh/100km`;

          this.formattedEmissionData.wltp[2].header = 'CO2-EMISSION (WLTP KOMBINIERT)';
          this.formattedEmissionData.wltp[2].hybridFuelConsumptionCombined = `${emission.wltp.fuelConsumptionCombined} g/km`;
          this.formattedEmissionData.wltp[3].header = 'VERBRAUCH (WLTP KOMBINIERT)';
          this.formattedEmissionData.wltp[3].hybridCo2Combined = `${emission.wltp.co2Combined} l/100km`;
        }
      } else {
        this.formattedEmissionData.nefz[0].header = 'VERBRAUCH (NEFZ)';
        this.formattedEmissionData.nefz[0].consumption = `${emission.nedcCorrelated.fuelConsumptionCombined} kWh/100km`;
        this.formattedEmissionData.nefz[1].header = 'CO2-EMISSION (NEFZ KOMBINIERT)';
        this.formattedEmissionData.nefz[1].emissionCombined = `${emission.nedcCorrelated.co2Combined} km`;

        this.formattedEmissionData.wltp[0].header = 'CO2-EMISSION (WLTP KOMBINIERT)';
        this.formattedEmissionData.wltp[0].emissionCombined = `${emission.wltp.fuelConsumptionCombined} g/km`;
        this.formattedEmissionData.wltp[1].header = 'VERBRAUCH (WLTP KOMBINIERT)';
        this.formattedEmissionData.wltp[1].consumptionCombined = `${emission.wltp.co2Combined} l/100km`;
      }
    }

    this.efficiencyCategoryIndex =
      this.efficiencyCategoryMapping[data.emissionData.efficiencyCategory.toLowerCase()];
  }
}
