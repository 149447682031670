<div class="hero">
  <app-video
    [isBrowserMozilla]="isBrowserMozilla"
    [src]="src"
    [isDrivingExp]="isDrivingExp"
    [videoId]="videoId"
    [isHero]="true"
    (isPlaying)="toggleVideoState($event)"
    [fileNameExtension]="isDrivingExp ? 'Emotional Drive' : 'Exterior'"></app-video>
  <div class="hero__overlay"></div>
  <div class="hero__text" [ngClass]="{ 'video-is-playing': videoPlaying }">
    <h1 [class]="(theme.name | async) + '-headline'">{{ modelName }}</h1>
    <h2 [class]="(theme.name | async) + '-subtitle'">{{ modelDescription }}</h2>
  </div>
  <img
    [ngClass]="videoPlaying ? 'video-is-playing' : ''"
    [class]="'hero__logo ' + (theme.name | async) + '-logo'"
    [src]="'/assets/themes/' + (theme.name | async) + '/logo.svg'"
    [alt]="(theme.name | async) + ' logo'" />
  <mat-select
    *ngIf="theme.getAvailableLanguages()?.length > 1"
    [ngClass]="videoPlaying ? 'video-is-playing' : ''"
    [value]="selectedLanguage | async"
    (selectionChange)="changeLanguage($event)">
    <mat-option [value]="langCode" *ngFor="let langCode of theme.getAvailableLanguages()">{{
      langCode
    }}</mat-option>
  </mat-select>
</div>
