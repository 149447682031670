import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IBrochureAssetResponse } from '../interfaces/api';
import { IEnvironment, IRegexImageGroups, IUISection, IUIStructure } from '../interfaces/ui';

const defaultENV: IEnvironment = {
  environment: 'Villa', // default env
  daytime: 'day', // default daytime
};

@Injectable({
  providedIn: 'root',
})
export class StructureService {
  private regexImage = new RegExp(
    '(?<hash>\\w+\\/\\w+\\/)(?<model>[a-zA-Z]+\\d+)_(?<market>[a-zA-Z]+)_(?<env>[a-zA-Z]+)_(?<daytime>[a-zA-Z]+)_(?<cam>cam_\\d+)'
  );
  private regexVideo = new RegExp(
    '(?<hash>\\w+\\/\\w+\\/)(?<model>[a-zA-Z]+\\d+)_(?<market>[a-zA-Z]+)_(?<env>[a-zA-Z]+)_(?<daytime>[a-zA-Z]+)_(?<animation>\\w+)'
  );
  private regexEMD = new RegExp(
    '(?<hash>\\w+\\/\\w+\\/)(?<model>[a-zA-Z]+\\d+)_(?<market>[a-zA-Z]+)_(?<env>DrivingExperience)_(?<location>[a-zA-Z]+)_(?<daytime>[a-zA-Z]+)_(?<animation>[A-Za-z]+_[A-Za-z]+)'
  );
  private structurJSON: Array<IUISection> | undefined;

  private activeBrochure: IBrochureAssetResponse | undefined;
  private availableStructures: Array<IUIStructure> = [];
  private activeEnvironment: IEnvironment = defaultENV;

  public readonly activeStructure$ = new BehaviorSubject<IUIStructure>(undefined);

  constructor(private http: HttpClient) {
    this.http
      .get<Array<IUISection>>('/assets/structure.json')
      .subscribe(structure => (this.structurJSON = structure));
  }

  /**
   * Set a new brochure which is required to generate a structure
   * After a new structure will be generated
   * @param brochure
   */
  public setActiveBrochure(brochure: IBrochureAssetResponse): void {
    this.activeBrochure = brochure;
    this.activeEnvironment = defaultENV; // reset env
    this.generatePossibleUIStructures();

    if (this.availableStructures.length === 0) {
      throw new Error('The brochure does not contain enough resources for displaying');
    }

    // Fallback if default env not available
    if (!this.availbeStructuresInclude(this.activeEnvironment)) {
      const newENV = { ...this.availableStructures[0].env };
      console.log(
        `Default env: ${this.activeEnvironment.environment} | ${this.activeEnvironment.daytime} not available`,
        `Switching to env: ${newENV.environment} | ${newENV.daytime}`
      );
      this.activeEnvironment = newENV;
    }

    this.publishNewStructure();
  }

  /**
   * Function to change environment
   * After a new structure will be generated
   * @param env
   */
  public setActiveEnvironment(env: IEnvironment): void {
    this.activeEnvironment = env;
    this.publishNewStructure();
    console.log('[CHANGING STRUCTURE]', env);
  }

  /**
   * Function to get all Available environments
   * @returns available environments
   */
  public getAvailableEnvironments(): Array<IEnvironment> {
    return this.availableStructures.map(structure => structure.env);
  }

  /**
   * Fuction to check if a structure contains a Driving Experience
   * @param structure
   */
  public structureContainsDrivingExperience(structure: Array<IUISection>): boolean {
    const idx = structure.findIndex((el: IUISection) => el.type === 'drivingexperience');
    return idx !== -1;
  }

  private generatePossibleUIStructures(): void {
    this.availableStructures = [];
    if (!this.activeBrochure) return;

    const resources = [
      ...this.activeBrochure.resources.images,
      ...this.activeBrochure.resources.videos,
    ];
    const generatedEnvironments: IEnvironment[] = [];

    const getEnvFromRegex = result => {
      const groups = result as IRegexImageGroups;
      if (groups.env && groups.daytime) {
        const env: IEnvironment = {
          environment: groups.env,
          daytime: groups.daytime,
        };
        // if env not in list add
        if (
          generatedEnvironments.findIndex(
            el => el.daytime === env.daytime && el.environment === env.environment
          ) === -1
        ) {
          generatedEnvironments.push(env);
        }
      }
    };

    // Look for availabe env´s and collect them
    resources.forEach(resource => {
      const resultImage = resource.match(this.regexImage)?.groups as unknown;
      const resultVideo = resource.match(this.regexVideo)?.groups as unknown;
      const resultEMD = resource.match(this.regexEMD)?.groups as unknown;
      if (resultImage) getEnvFromRegex(resultImage);
      if (resultVideo && !resultEMD) getEnvFromRegex(resultVideo);
    });

    const availableStructures: IUIStructure[] = [];
    if (
      (resources.length === 16 || resources.length === 17) &&
      generatedEnvironments.length === 2
    ) {
      // Case Resource has multiple env´s, but is only rendered in video/image not complete in both env´s
      try {
        const imageEnv: IEnvironment = generatedEnvironments[0];
        const structure = this.generateStructure(imageEnv, generatedEnvironments[1]);
        if (structure) {
          availableStructures.push({
            env: imageEnv,
            uiSection: structure,
          });
        }
      } catch (e) {
        console.warn(
          e,
          `There are not enough resources to display this env: ${generatedEnvironments[0].environment} | ${generatedEnvironments[0].daytime}`
        );
      }
    } else {
      // Try to build a structure for each env
      generatedEnvironments.forEach(env => {
        try {
          const structure = this.generateStructure(env);
          if (structure) {
            availableStructures.push({
              env,
              uiSection: structure,
            });
          }
        } catch (e) {
          console.warn(
            e,
            `There are not enough resources to display this env: ${env.environment} | ${env.daytime}`
          );
        }
      });
    }

    this.availableStructures = availableStructures;
    console.log('[AVAILABLE STRUCTURES]', this.availableStructures);
  }

  /**
   * Function for building new structure
   * It will map indeces from structure.json to new indeces with applied env
   * Will throw an error if not enought resources for env are given
   * @param activeEnvironment environment for finding resources - default this.activeEnvironment
   * @returns new Structure
   */
  private generateStructure(
    activeEnvironment: IEnvironment = this.activeEnvironment,
    videoEnvironment: IEnvironment | undefined = undefined
  ): Array<IUISection> {
    const images = this.getMatchingImageIndicesFromBrochure(activeEnvironment);
    const videos = videoEnvironment
      ? this.getMatchingVideoIndicesFromBrochure(videoEnvironment)
      : this.getMatchingVideoIndicesFromBrochure(activeEnvironment);
    let structure: IUISection[] = [];

    this.structurJSON.forEach((section: IUISection) => {
      if (section.type === 'image' || section.type === 'carousel') {
        const foundImages = section.indeces.map(x => images[x]);
        if (foundImages.includes(undefined)) {
          throw new Error('The brochure does not contain enough images to display');
        }
        structure.push({
          ...section,
          indeces: foundImages,
        });
      } else if (section.type === 'video') {
        const foundVideos = section.indeces.map(x => videos[x]);
        if (foundVideos.includes(undefined)) {
          throw new Error('The brochure does not contain enough videos to display');
        }
        structure.push({
          ...section,
          indeces: foundVideos,
        });
      } else if (section.type === 'hero') {
        const video = videos[section.index];
        if (video <= -1 || video === undefined) {
          throw new Error('The brochure does not contain enough videos to display the hero video');
        }
        structure.push({ ...section, index: video });
      }
    });

    const EMDIndex = this.searchForEMD();
    if (EMDIndex > -1) {
      structure.unshift({
        type: 'drivingexperience',
        index: EMDIndex,
      });
    }

    return structure;
  }

  /**
   * Publish a structure if a structure is avalable for env
   */
  private publishNewStructure(): void {
    if (!this.availbeStructuresInclude(this.activeEnvironment)) {
      this.activeStructure$.next(undefined);
      return;
    }
    const structure = this.availableStructures.find(
      el =>
        el.env.daytime === this.activeEnvironment.daytime &&
        el.env.environment === this.activeEnvironment.environment
    );
    this.activeStructure$.next(structure);
  }

  /**
   * Function to find EMD in brochure
   * @returns index of EMD video in brochure
   */
  private searchForEMD(): number {
    for (const [i, video] of this.activeBrochure.resources.videos.entries()) {
      const match = video.match(this.regexEMD);
      if (match && match.groups) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Function to check if an environemnt exist in availableStructures
   * @param env
   * @returns
   */
  private availbeStructuresInclude(env: IEnvironment): boolean {
    return (
      this.availableStructures.findIndex(
        el => el.env.daytime === env.daytime && el.env.environment === env.environment
      ) >= 0
    );
  }

  /**
   * Function to get images based on environment
   * @param activeEnvironment environment for finding images - default this.activeEnvironment
   * @returns
   */
  private getMatchingImageIndicesFromBrochure(
    activeEnvironment: IEnvironment = this.activeEnvironment
  ): Array<number> {
    if (!this.activeBrochure) return [];

    const images = [];
    this.activeBrochure.resources.images.forEach((el: string, index: number) => {
      const result = el.match(this.regexImage)?.groups as unknown;
      if (!result) return;

      const groups = result as IRegexImageGroups;
      if (
        groups.env === activeEnvironment.environment &&
        groups.daytime === activeEnvironment.daytime
      ) {
        images.push(index);
      }
    });
    return images;
  }

  /**
   * Function to get videos based on environment
   * @param activeEnvironment environment for finding videos - default this.activeEnvironment
   * @returns
   */
  private getMatchingVideoIndicesFromBrochure(
    activeEnvironment: IEnvironment = this.activeEnvironment
  ): Array<number> {
    if (!this.activeBrochure) return [];

    const videos = [];
    this.activeBrochure.resources.videos.forEach((el: string, index: number) => {
      const result = el.match(this.regexVideo)?.groups as unknown;
      if (!result) return;

      const groups = result as IRegexImageGroups;
      if (
        groups.env === activeEnvironment.environment &&
        groups.daytime === activeEnvironment.daytime
      ) {
        videos.push(index);
      }
    });
    return videos;
  }
}
