import { Component } from '@angular/core';
import { LightboxService } from './lightbox.service';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent {
  constructor(public lightbox: LightboxService) {}
}
