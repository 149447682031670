import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  public modelTranslations;
  public iModels;
  public translations = new BehaviorSubject({});

  constructor(private http: HttpClient) {
    this.http.get('/assets/modelnames.json').subscribe(res => {
      this.modelTranslations = res;
    });
    this.http.get('/assets/en.json').subscribe(res => {
      this.translations.next(res);
    });
    this.http.get('/assets/i-models.json').subscribe(res => {
      this.iModels = res;
    });
  }

  public getModelName(brand: string, id: string, agModelCode: string): string {
    const isIModel = this.iModels.includes(agModelCode);
    let translationKey = id.toLowerCase();
    if (isIModel) translationKey += 'i';

    const translationKeyFound = Object.keys(this.modelTranslations).find(
      key => key.toLowerCase() === translationKey
    );
    if (!translationKeyFound) {
      console.warn('[Translation missing] ', translationKey);
      return '';
    }

    return this.modelTranslations[translationKeyFound];
  }

  public getTranslation(key: string): Observable<string> {
    return this.translations.pipe(
      switchMap(translations => {
        return of(translations[key]);
      }),
      filter(value => !!value)
    );
  }
}
