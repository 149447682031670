<mat-card>
  <mat-card-content>
    <form [formGroup]="reactiveForm" (ngSubmit)="onMFASet()">
      <h3 *ngIf="firstLogin">Configure MFA</h3>
      <label *ngIf="firstLogin" for="authCode"
        >1. Please scan QR code in your authenticator app</label
      >
      <canvas #qrCanvas [width]="firstLogin ? 300 : 0" [height]="firstLogin ? 300 : 0"></canvas>
      <label *ngIf="!firstLogin">Please enter code from your authenticator app</label>
      <mat-form-field>
        <label *ngIf="firstLogin">2. Please enter code from authenticator app</label>
        <input
          matInput
          id="authCode"
          type="text"
          placeholder="auth code"
          formControlName="authCode"
          required />
        <mat-error *ngIf="submitted && f['authCode'].errors as errors">
          <span *ngIf="errors['required']"> A code is required </span>
          <span *ngIf="errors['minlength'] || errors['maxlength'] || errors['pattern']">
            Code must be 6 characters long digits</span
          >
        </mat-error>
      </mat-form-field>
      <button mat-button color="primary">
        <mat-spinner diameter="20" *ngIf="loading"></mat-spinner>
        <span *ngIf="!loading">continue</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>
