import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IEnvironment } from 'src/app/interfaces/ui';
import { ApiService } from 'src/app/services/api.service';
import { StructureService } from 'src/app/services/structure.service';
import { ThemeService } from 'src/app/services/theme.service';
import { WindowService } from 'src/app/services/window.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, AfterViewInit {
  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;
  @ViewChild('videoContainer') videoContainer: ElementRef<HTMLDivElement>;

  @Input() isDrivingExp: boolean = false;
  @Input() isHero: boolean = false;
  @Input() scrollMagicId: string;
  @Input() videoId: string;
  @Input() isBrowserMozilla: boolean;

  private _src: string;
  private playedFirstTime = true;
  private brochureContainerElement = document.getElementById('brochure-container');
  public loaded: boolean;

  public videoDuration = 0;
  public videoCurrentTime = 0;

  public availableEnvs: Array<IEnvironment> = [];
  public activeEnv: IEnvironment;

  private _videoCurrentVolume = 1;

  private mouseMoveTimer;
  public showVideoTransportControls = false;
  public videoStarted = false;

  /* Download */
  public firstPartOfFilename = '';
  public prodMode = environment.production;

  public get videoCurrentVolume() {
    return this._videoCurrentVolume;
  }

  public set videoCurrentVolume(value: number) {
    this._videoCurrentVolume = value;
    this.videoElement.nativeElement.volume = value;
  }

  public videoTranslate: any = {
    x: '0',
    y: '0',
  };

  @Input() public set src(url: string) {
    if (url) {
      this._src = url;
      this.setDownloadFilename(url);
    }
  }
  public get src(): string {
    return this._src;
  }

  @Input() fileNameExtension: string = '';

  public paused = true;

  private _loaded = false;

  private _visibleInWindow = false;

  @Output() isPlaying = new EventEmitter<boolean>();

  constructor(
    public theme: ThemeService,
    public window: WindowService,
    private api: ApiService,
    private structureService: StructureService
  ) {}

  ngOnInit(): void {
    this.api.getModelName().subscribe(modelName => this.setDownloadFilename(modelName));

    this.structureService.activeStructure$.subscribe(structure => {
      if (!structure) return;
      this.availableEnvs = this.structureService.getAvailableEnvironments();
      this.activeEnv = structure.env;
    });
  }

  ngAfterViewInit(): void {
    if (this.isHero) {
      combineLatest([
        this.window.size$,
        this.api.videoPositions,
        this.api.modelRange,
        this.api.loading$.pipe(filter(loading => !loading)),
      ]).subscribe(([[windowWidth, windowHeight], videoPositions, modelRange]) => {
        let translateX = '-50%';
        let translateY = '0';
        let positionTop = '0';

        /* ModelRange-specific translations
          defined in: assets/video-positioning.json
          as: {[modelRangeId: number]} where the number is the percentage the video needs to be translated on the X axis with CSS transform
        */
        const modelRangeSpecificTranslateX = videoPositions[modelRange];
        if (!!modelRangeSpecificTranslateX && typeof modelRangeSpecificTranslateX === 'number') {
          const videoWidth = this.videoElement.nativeElement.offsetWidth;
          const translateXDifferenceInPercent = 50 + modelRangeSpecificTranslateX;
          const translateXInPixel = videoWidth * (translateXDifferenceInPercent / 100);
          const translatingVideoWithoutWhiteBorderPossible =
            videoWidth >= windowWidth + 2 * translateXInPixel;

          if (translatingVideoWithoutWhiteBorderPossible) {
            translateX = modelRangeSpecificTranslateX + '%';
          }
        }

        // Translations based on orientation and aspect-ratio
        const isLandscape = windowWidth > windowHeight;
        const isWiderThan16to9 = windowWidth / windowHeight > 1.77; // examples: 16:9 -> 1.77 | 21:9 -> 2.3
        if ((isLandscape && windowWidth < 950) || isWiderThan16to9) {
          translateY = '-50%';
          positionTop = '50%';
        }

        // Apply translations
        this.videoTranslate = {
          x: translateX,
          y: translateY,
          top: positionTop,
        };
      });
    }
  }

  public onLoad(): void {
    this.api.onAssetLoadFinished(this._src);
    this.loaded = true;
    this.addEventToPauseVideoOnScroll();

    if (this.isDrivingExp) {
      this.videoElement.nativeElement.currentTime = 39;
    }

    this.videoDuration = this.videoElement.nativeElement.duration;
    this.videoCurrentVolume = this.videoElement.nativeElement.volume;
  }

  public onError(): void {
    this.api.onAssetLoadFailed(this._src);
  }

  public progress(e): void {
    if (!this._loaded && this._isiOS()) {
      this.api.onAssetLoadFinished(this._src);
      this._loaded = true;
    }
  }

  public playPause(): void {
    const video = this.videoElement.nativeElement as HTMLVideoElement;
    if (video.paused) {
      if (!this.videoStarted) {
        this.videoStarted = true;
      }
      this.enableVideoShowroom();
      if (this.isDrivingExp && this.playedFirstTime) {
        this.videoElement.nativeElement.currentTime = 0;
      } else {
        video.play();
      }
    } else {
      video.pause();
      this.disableVideoShowroom();
    }
    this.isPlaying.emit(!video.paused);
  }

  public videoTimeUpdate(): void {
    const video = this.videoElement.nativeElement as HTMLVideoElement;
    this.videoCurrentTime = video.currentTime;

    if (
      this.isDrivingExp &&
      this.playedFirstTime &&
      this.videoElement.nativeElement.currentTime === 0
    ) {
      this.initialPlayDrivingExperience();
    }

    if (this.videoCurrentTime >= video.duration - 1) {
      this.videoStarted = false;
    }
  }

  public toggleVideoMute($event): void {
    const video = this.videoElement.nativeElement as HTMLVideoElement;

    if (video.volume > 0) {
      this.videoCurrentVolume = 0;
    } else {
      this.videoCurrentVolume = 1;
    }

    $event.stopPropagation();
  }

  public initialPlayDrivingExperience(): void {
    const video = this.videoElement.nativeElement as HTMLVideoElement;
    video.play();
    this.isPlaying.emit(true);
    this.playedFirstTime = false;
  }

  public replayVideo() {
    const video = this.videoElement.nativeElement as HTMLVideoElement;
    this.enableVideoShowroom();
    video.currentTime = 0;
    video.play();
    this.isPlaying.emit(!video.paused);
    this.videoStarted = false;
  }

  public videoEnded(): void {
    const video = this.videoElement.nativeElement as HTMLVideoElement;
    video.currentTime = 0;
    this.paused = true;
    this.disableVideoShowroom();

    if (this.isDrivingExp) {
      this.playedFirstTime = true;
      this.videoElement.nativeElement.currentTime = 39;
    }

    this.isPlaying.emit(false);
  }

  public videoPaused(event: any): void {
    this.disableVideoShowroom();
    this.paused = true;
  }

  public videoPlayed(event: any): void {
    if (this._isMobileDevice() && !this._isiOS()) {
      event.target.requestFullscreen();
    }

    this.paused = false;
  }

  public setDownloadFilename(modelName: string): void {
    const name = modelName.replace(/\./g, ''); // delete possible '.' from modelname for correct filextensions
    this.firstPartOfFilename = `${name} - `; // Full name is 'model - videotype(ext/int/emd).mp4'
  }

  public downloadFile(url: string, filename: string): void {
    fetch(url)
      .then(resp => (resp.status === 200 ? resp.blob() : Promise.reject('something went wrong')))
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('oh no!'));
  }

  public skipInVideo(event: PointerEvent): void {
    event.stopPropagation();
    const target: HTMLElement = event.target as HTMLElement;
    const progressBarWidth = target.offsetWidth;

    const clickOffset = event.offsetX;

    const skipToPosition = (clickOffset / progressBarWidth) * this.videoDuration;

    this.videoElement.nativeElement.currentTime = skipToPosition;
  }

  public changeEnv(env: IEnvironment): void {
    if (env.daytime === this.activeEnv.daytime && env.environment === this.activeEnv.environment)
      return;
    // Set loading to true to get loading screen until new assets are loaded
    this.api.loading$.next(true);
    this.structureService.setActiveEnvironment(env);
  }

  public onMouseMove(): void {
    this.showVideoTransportControls = true;
    clearTimeout(this.mouseMoveTimer);
    this.mouseMoveTimer = setTimeout(() => {
      this.showVideoTransportControls = false;
    }, 2000);
  }

  private addEventToPauseVideoOnScroll(): void {
    document.addEventListener('scroll', () => {
      const windowHeight = window.innerHeight;
      const videoTop = this.videoElement.nativeElement.getBoundingClientRect().top;
      const videoBot = this.videoElement.nativeElement.getBoundingClientRect().bottom;

      if (videoTop <= windowHeight && videoBot >= 0) {
        this._visibleInWindow = true;
      } else {
        if (this._visibleInWindow) {
          if (!this.paused) this.playPause();
          this._visibleInWindow = false;
        }
      }
    });
  }

  private enableVideoShowroom() {
    this.brochureContainerElement.classList.add('video-showroom');
    this.videoContainer.nativeElement.classList.add('video-showroom');
  }

  private disableVideoShowroom() {
    this.brochureContainerElement.classList.remove('video-showroom');
    this.videoContainer.nativeElement.classList.remove('video-showroom');
  }

  private _isiOS(): boolean {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }

  private _isMobileDevice(): boolean {
    return 'ontouchend' in document && window.innerWidth < 750;
  }
}
