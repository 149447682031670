import { Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// @ts-ignore
import QRCode from 'qrcode';
import { firstValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { LoginRedirectRequest } from 'src/app/utils/auth';

@Component({
  selector: 'app-mfa-login',
  templateUrl: './mfa-login.component.html',
  styleUrls: ['./mfa-login.component.scss'],
})
export class MfaLoginComponent {
  @ViewChild('qrCanvas') private qrCanvas: ElementRef<HTMLCanvasElement> | undefined;
  public reactiveForm = new FormGroup({
    authCode: new FormControl(''),
  });
  public firstLogin = false;
  public submitted = false;
  public loading = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) {}

  get f(): { [key: string]: AbstractControl } {
    return this.reactiveForm.controls;
  }
  public async ngOnInit(): Promise<void> {
    this.reactiveForm = this.formBuilder.group({
      authCode: [
        '',
        [
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
          Validators.pattern('^[0-9]+$'),
        ],
      ],
    });
    this.firstLogin = this.authService.firstLogin;
    // Skip QR CODE setup if user has already a MFA setup
    if (!this.firstLogin) return;
    try {
      const secret = await this.authService.setupMFA();
      if (typeof secret === 'string' && this.qrCanvas) {
        QRCode.toCanvas(this.qrCanvas.nativeElement, secret);
      }
    } catch (error: unknown) {
      console.error(error);
    }
  }

  public async onMFASet(): Promise<void> {
    this.submitted = true;

    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    let queryParams = {};
    if (returnUrl != '/') {
      queryParams = { queryParams: { returnUrl: returnUrl } };
    }
    if (this.reactiveForm.invalid) return;
    this.loading = true;
    const { authCode } = this.reactiveForm.value;
    try {
      this.firstLogin
        ? await this.authService.verifySetupMFACode(authCode!)
        : await this.authService.sendLoginMFACode(authCode!);

      if (!this.firstLogin) {
        this.authService.syncAuthData();
      }

      const hostname = window.location.origin;
      window.location.href = hostname + returnUrl;
    } catch (e) {
      this.loading = false;
      if (e === LoginRedirectRequest.VerifyEmail) {
        console.log('need to verify email');
        this.router.navigate(['login', 'verify'], queryParams);
        return;
      }
      const loginError = e instanceof Error ? e.message : 'Code is not correct or expired.';
      console.error(loginError);
      this.snackBar.open(loginError, 'Close', {
        duration: 3000, // Duration in milliseconds
        panelClass: ['mat-toolbar', 'mat-warn'], // Optional: add custom classes for styling
        verticalPosition: 'top',
      });
      if (loginError === 'Can not access user before creation.') {
        this.router.navigate(['login'], queryParams);
      }
      return;
    }
  }
}
